import React from "react";
import Tools from "./Tools";

const MyTools = () => {
  return (
    <div>
      <h1 className="projectHeading">
        My <b>Tools </b>&<b> Apps</b>
      </h1>
      <p className="project">
        i will add more tools and apps here in the future. stay tuned!
      </p>

      <div className="project">
        <Tools
          projectPhoto={
            "https://fritzing.org/assets/fritzing-logo-white-b61f5b9739bd51a78cb6f933acfee421550282b4d0ce191b2bf714fe8602cd5a.png"
          }
          projectName="Fritzing"
          download="assets/fritzing-1.0.3-l1995-a7811ba0-win64_qt6.msi"
          link="https://fritzing.org/"
          desc="Fritzing is an open-source hardware initiative that makes electronics accessible as a creative material for anyone. We offer a software tool, a community website and services in the spirit of Processing and Arduino, fostering a creative ecosystem that allows users to document their prototypes, share them with others, teach electronics in a classroom, and layout and manufacture professional PCBs."
        />
      </div>
    </div>
  );
};

export default MyTools;
