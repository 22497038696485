import React from "react";
import { CgArrowRight, CgWebsite } from "react-icons/cg";

const Tools = ({ projectPhoto, projectName, download, link, desc, show }) => {
  return (
    <div className="projectBox">
      <img className="projectPhoto" src={projectPhoto} alt="Project display" />
      <div>
        <br />
        <h3>{projectName}</h3>
        <br />

        <p>{desc}</p>
        <br />

        <a href={link} target="_blank">
          <button className="projectbtn">
            <CgWebsite /> official site
          </button>
        </a>

        <a href={download} target="_blank">
          <button className="projectbtn">
            <CgArrowRight /> Get me!
          </button>
        </a>
      </div>
    </div>
  );
};

export default Tools;
