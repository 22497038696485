import Lottie from "lottie-react";
import React from "react";
import { CiCoffeeCup } from "react-icons/ci";
import Tilt from "react-parallax-tilt";
import Avatar from "../images/undraw_jogging_re_k28i.svg";
import Zerwone from "../LottieFiles/zerwone.json";
import Typed from "./Typed.js";

const Home = () => {
  const strings = [
    "Full-Stack Developer",
    "MERN Stack Developer",
    "Cybersecurity Enthusiast",
    "Blockchain Developer",
    "AI & Machine Learning Enthusiast",
    "Cloud Computing Specialist",
    "UI/UX Designer",
    "Software Engineer",
    "React.js & Node.js Expert",
    "Open Source Contributor",
    "Technology Innovator",
    "Gamer:)",
  ];

  const stringsMynames = ["Mohamad Aljadaan", "Mo", "ZerwOne", "Zero", ":)"];
  return (
    <div>
      <div className="HomePage">
        <div className="HomeText">
          <h1>Hi There!</h1>
          <h1 style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            I'M
            <b style={{ display: "inline-block" }}>
              <Typed strings={stringsMynames} />
            </b>
          </h1>
          <Typed
            strings={strings}
            delay={150}
            deleteSpeed={20}
            autoStart={true}
            loop={true}
          />
        </div>

        <Lottie className="illustration" animationData={Zerwone} loop={true} />
      </div>
      <div className="AboutPage">
        <div className="AboutText">
          <h1 className="AboutTextHeading">
            <b>Hey there, Tech Geek!</b>
          </h1>
          <p>
            Welcome to my corner of the digital world! I'm Mohamad Aljadaan,
            also known as <b>ZerwOne</b>, a full-stack developer with a passion
            for tech, computers, and all things geeky.
            <br />
            From crafting MERN stack apps to diving into the depths of
            <b> JavaScript</b>, <b>Python</b>, and a bit of <b>C++</b>.<br />
            I’m always on the hunt for the next big challenge.
            <br />
            Currently, I’m a student pursuing Cybersecurity, exploring the
            exciting world of protecting digital spaces.
            <br />
            I’ve got my 👀 on Machine Learning too, and I plan to dive into that
            soon.
            <br />
            When I’m not coding or studying, you can bet I’m enjoying a good cup
            of coffee! <CiCoffeeCup style={{ scale: "1.5", rotate: "15deg" }} />
            <br />
            Join me as I navigate the tech landscape and share my journey with
            you.
            <br />
            Let’s geek out together!
            <br />
            <br />
            <b>Future Goals:</b> Diving into <b>Machine Learning</b>, enhancing{" "}
            <b>Cybersecurity</b>, and more!
            <br />
            <br />
          </p>
        </div>
        <Tilt>
          <img className="Avatar" src={Avatar} alt="" />
        </Tilt>
      </div>
    </div>
  );
};

export default Home;
