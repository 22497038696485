import React from "react";

const Projects = () => {
  return (
    <div>
      <h1 className="projectHeading">
        My <b>Projects</b>
      </h1>
      <div className="project">
        {/* <ProjectBox projectPhoto={WigglesImage} projectName="Wiggles" />
        <ProjectBox projectPhoto={NewsletterImage} projectName="Newsletter" />
        <ProjectBox projectPhoto={RogfreeImage} projectName="RogFree" />
        <ProjectBox projectPhoto={TindogImage} projectName="Tindog" /> */}

        <p>
          Currently, my projects are not available to the public. Stay tuned for
          updates as I work on showcasing my latest creations!
        </p>
      </div>
    </div>
  );
};

export default Projects;
