import Lottie from "lottie-react";
import React from "react";
import Tilt from "react-parallax-tilt";
import Attaker from "../LottieFiles/attacker.json";
import Skills from "./Skills.js";

const About = () => {
  return (
    <>
      <div className="AboutPage">
        <div className="AboutText">
          <h1 className="AboutTextHeading">
            Get to <b>know</b> me!
          </h1>
          <p>
            Hi there! 👋 I'm <b>Mohamad Aljadaan</b>, hailing from the vibrant
            city of <b>Damascus, Syria</b>. I'm a passionate{" "}
            <b>full-stack developer</b> and currently diving into the world of{" "}
            <b>Cybersecurity</b> as a freshman at University of Cincinnati.{" "}
            <br />
            <br />
            When I’m not coding up a storm or tackling cybersecurity challenges,
            you’ll find me working on exciting, original projects with sleek
            designs and innovative features. From building cloud services and
            creating custom blockchain solutions and working with AI, I love
            turning ideas into reality! <br />
            Apart from coding, I enjoy exploring various technologies and
            creative projects.
            <br />
            Feel free to connect with me; links are in the footer.
            <br />
          </p>
        </div>

        <div>
          <Tilt>
            <Lottie
              className="illustration"
              style={{ height: "400px", width: "-webkit-fill-available" }}
              animationData={Attaker}
              loop={true}
            />
          </Tilt>
        </div>
      </div>

      <h1 className="SkillsHeading">
        My <b>Skills</b>
      </h1>
      <div className="skills">
        <Skills skill="Javascript" />
        <Skills skill="Python" />
        <Skills skill="C++" />
        <Skills skill="Npm" />
        <Skills skill="React" />
        <Skills skill="NextJS" />
        <Skills skill="Node" />
        <Skills skill="Express" />
        <Skills skill="MongoDb" />
        <Skills skill="Git" />
        <Skills skill="Github" />
        <Skills skill="Discord" />
        <Skills skill="Postman" />
        <Skills skill="Vercel" />
        <Skills skill="Bootstrap" />
        <Skills skill="Tailwind" />
        <Skills skill="Docker" />
        <Skills skill="DiAws" />
        <Skills skill="Google Cloud" />
        <Skills skill="Linux" />
        <Skills skill="Windows" />
      </div>
    </>
  );
};

export default About;
